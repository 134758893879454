import optimizeEmptyConnectionsStruct from "../helpers/workflow/optimizeEmptyConnectionsStruct.js";
import getIngoingConnections from "../helpers/workflow/connections/getIngoingConnections.js";
import getOutgoingConnections from "../helpers/workflow/connections/getOutgoingConnections.js";
import deleteNodeConnections from "../helpers/workflow/connections/deleteNodeConnections.js";

export default {
    props: {
        connections: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tempConnection: null,
        }
    },
    methods: {
        /**
         * @param {MouseEvent} event
         */
        startConnecting(event, node) {

            // it is forbidden to create a connection from one output
            if(node?.overwrittenNodeType?.outputs){
                const currentConects = node.overwrittenNodeType.outputs.slice(0, node.overwrittenNodeType.outputs.length - 1)
                if(currentConects.includes(event.target.getAttribute('data-name'))) return;
            }
            
            if(event.button !== 0) return;

            const bounds = this.$refs.root.getBoundingClientRect();
            const targetBounds = event.target.getBoundingClientRect();

            const position = [
                (targetBounds.left - (bounds.left - this.position[0]))/this.rightOptions.scale + event.target.clientWidth / 2,
                (targetBounds.top  - (bounds.top + this.position[1]))/this.rightOptions.scale + event.target.clientHeight / 2,
            ];

            this.tempConnection = {
                startPosition: position,
                startNode: node,
                startNodeOutputName: event.target.getAttribute('data-name'),
                endPosition: position,
                locked: false,
                outputMouseLeave: false,
            };

            this.$emit('startConnecting');
        },

        /**
         * @param  {MouseEvent} event
         */
        followMouseConnecting(event) {
            if(!this.tempConnection || event.button !== 0 || this.tempConnection.locked) return;

            const bounds = this.$refs.root.getBoundingClientRect();
            this.tempConnection.endPosition = [
                (event.clientX - (bounds.left - this.position[0]))/this.rightOptions.scale,
                (event.clientY - (bounds.top + this.position[1]))/this.rightOptions.scale
            ];
        },

        connectionNodeEnter(event, node) {
            if(!event || !node) {
                console.error('Cant connection node enter, wrong params');
                return
            }

            /**
             * Отменить соединение, если нет временной информации, нода является концом и началом одновременно, у ноды нет входов
             */
            if(!this.tempConnection || node.id === this.tempConnection.startNode.id || !this.fullAvailableNodes[node.type].input) return;

            this.tempConnection.locked = true;

            const endNodeComponent = this.$refs[`node-${node.id}`][0];
            const input = endNodeComponent.$refs['input-main'];

            const bounds = this.$refs.root.getBoundingClientRect();

            this.tempConnection.endPosition = [
                (input.getBoundingClientRect().left - (bounds.left - this.position[0]))/this.rightOptions.scale + input.clientWidth/2,
                (input.getBoundingClientRect().top - (bounds.top + this.position[1]))/this.rightOptions.scale + input.clientHeight/2,
            ];

            this.tempConnection.endNode = node;
        },

        resetTempConnection() {
            this.tempConnection = null;
        },

        resetLockTempConnection() {
            this.tempConnection.locked = false;
            this.tempConnection.endNode  = null;
        },

        connectionNodeLeave() {
            if(!this.tempConnection) return;

            this.resetLockTempConnection();
        },

        addConnection(startNode, startNodeOutputName, endNode) {
            if(!startNode || !startNodeOutputName || !endNode) {
                console.error('Cant add connection, wrong params');
                return;
            }

            this.takeSnapshot();

            const updatedConnections = {...this.connections};

            if(Object.hasOwnProperty.call(this.connections, startNode.id)) {
                const connection = updatedConnections[startNode.id];

                if(Object.hasOwnProperty.call(connection, startNodeOutputName)) {
                    const index = connection[startNodeOutputName].findIndex((elem) => elem === endNode.id);

                    if(index === -1) {
                        connection[startNodeOutputName].push({
                            endNodeId: endNode.id
                        });
                        this.$emit('update:connections', updatedConnections);
                        this.$emit('connectNode', {
                            startNodeId: startNode.id,
                            startNodeOutputName: startNodeOutputName,
                            endNodeId: endNode.id
                        });
                    }

                } else {
                    const isHaveConnect = Object.values(connection).some(item => item[0].endNodeId === endNode.id)

                    if(!isHaveConnect){
                        connection[startNodeOutputName] =  [{
                            endNodeId: endNode.id,
                        }];

                        this.$emit('update:connections', updatedConnections);
                        this.$emit('connectNode', {
                            startNodeId: startNode.id,
                            startNodeOutputName: startNodeOutputName,
                            endNodeId: endNode.id
                        });
                    }
                }
            } else {
                const outputName = startNodeOutputName;

                updatedConnections[startNode.id] = {};
                updatedConnections[startNode.id][outputName] = [{
                    endNodeId: endNode.id
                }];

                this.$emit('update:connections', updatedConnections);
                this.$emit('connectNode', {
                    startNodeId: startNode.id,
                    startNodeOutputName: startNodeOutputName,
                    endNodeId: endNode.id
                });
            } 
        },

        /**
         * При отпускании мышки, если было начато создание связи, начинает проверку на дубликат связи и если всё в порядке,
         * то добавляет новое соединение.
         * После очищает создание связи.
         *
         * @param {MouseEvent} event
         */
        connectionMouseUp(event) {
            if(!this.tempConnection || event.button !== 0) return;

            if(this.tempConnection.endNode) {
                this.addConnection(this.tempConnection.startNode, this.tempConnection.startNodeOutputName, this.tempConnection.endNode);
            } else {
                this.setFutureInfoOpenNodeList(event, this.tempConnection.startNode, this.tempConnection.startNodeOutputName);
            }

            this.tempConnection = null;
        },
        deleteNodeConnections(node) {
            if(!node) {
                console.error('Cant delete node, wrong params');
                return;
            }
            this.takeSnapshot();

            if(Object.hasOwnProperty.call(this.connections, node.id)) {
                this.$delete(this.connections, node.id);
            }

            const updatedConnections = deleteNodeConnections(node.id, this.connections);

            this.$emit("update:connections", updatedConnections);
            this.$emit('deleteNodeConnections', node);
        },
        deleteConnection(startNodeId, startNodeOutputName, endNodeId) {
            if(!startNodeId || !startNodeOutputName || !endNodeId) {
                console.error('Cant delete connection, wrong params');
                return;
            }

            const flatEndNodeIds = this.connections[startNodeId][startNodeOutputName].map(endNodeObject => endNodeObject['endNodeId']);

            if(!flatEndNodeIds.includes(endNodeId)) {
                console.error('Cant remove connection, current connection doesnt exists');
                return;
            }

            this.takeSnapshot();

            const updatedConnections = {...this.connections};

            updatedConnections[startNodeId][startNodeOutputName] = updatedConnections[startNodeId][startNodeOutputName].filter(endNodeObject => endNodeObject['endNodeId'] !== endNodeId);

            this.$emit('deleteConnection', {
                startNodeId,
                startNodeOutputName,
                endNodeId
            });

            this.$emit('update:connections', updatedConnections);
            this.optimizeEmptyConnections();
        },
        optimizeEmptyConnections() {
            this.$emit('update:connections', optimizeEmptyConnectionsStruct(this.connections));
        },
        getNodeOutgoingConnections(nodeId) {
            return getOutgoingConnections(nodeId, this.connections);
        },
        getNodeIngoingConnections(nodeId) {
            return getIngoingConnections(nodeId, this.connections);
        }
    },
    computed: {
        connectionNodePreparedInfo() {
            const result = [];

            Object.entries(this.connections).forEach(startPair => {
                const startNodeId = startPair[0];
                const startNodeOutputsInfo = startPair[1];

                Object.entries(startNodeOutputsInfo).forEach(outputPair => {
                    const outputName = outputPair[0];
                    const endNodeIds = outputPair[1];

                    endNodeIds.forEach(endNodeObject => {
                        if(!this.$refs[`node-${startNodeId}`] || !this.$refs[`node-${endNodeObject['endNodeId']}`]) {
                            return;
                        }

                        const startNodeComponent = this.$refs[`node-${startNodeId}`][0];
                        const endNodeComponent = this.$refs[`node-${endNodeObject['endNodeId']}`][0];

                        if (!startNodeComponent || !endNodeComponent) {
                            console.error(startNodeComponent, endNodeComponent);
                            console.error("Connection cant be prepared for rendering");
                            return;
                        }

                        const lineOffset = endNodeObject?.lineOffset
                        const typeLine = endNodeObject?.typeLine
                        result.push({
                            startNodeId: startNodeId,
                            startNodeComponent,
                            startNodeOutputName: outputName,
                            endNodeId: endNodeObject['endNodeId'],
                            endNodeComponent,
                            lineOffset,
                            typeLine
                        });
                    });
                });
            });

            return result;
        },
    },
};
